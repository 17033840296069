import React from "react";

const ResetBtn = (props) =>{

    return(
        <>
        <button onClick = {props.resetAllFilters} className="reset_button bg-primary" size="sm">Reset</button>
        </>
    )
}

export default ResetBtn;
