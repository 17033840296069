import React from "react";
import LogOut from "../Components/List/Logout";
import { Row, Col } from "react-bootstrap";

const Header = ({ totalEmails }) => {
  return (
    <>
      <div className="header">
        <div className="inner">
          <Row>
            <Col className="flex-fill col-auto">
              <h4>marbet message-center</h4>
            </Col>
            <Col className="col-lg-1 col-sm-2 col-md-1 col-xs-2 col-xxs-2">
              <LogOut />
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <h3>Emails ({totalEmails})</h3>
          </Col>
        </Row>
      </div>
    </>
  )
};

export default Header;