import React from "react";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const ListByDate=(props)=>{
    return(
    <>
     <DateRangePicker
        className="d-block"
        onOk={props.getListByDate}
        size={'md'}
        format="dd/MM/yyyy,HH:mm:ss"
        defaultValue={props.selectedDateValue}
        onClean={props.clearDate}
     />
    </>
    )
}

export default ListByDate;