import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter} from 'react-router-dom';
import "./index.css";
import "../src/sass/main.scss";
import "./fonts/Roboto-Regular.otf";

const root=document.querySelector("#root");
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
 ,
root);
     
  
