import React, { useContext } from "react";
import { ParticipantContext } from "../../Context/ParticipantState";
import Select from 'react-select';

const ListBySender = (props) => {
  // sender contains all data (json file / array of Objects / records)
  const { sender } = useContext(ParticipantContext);
  //remove duplicate value of senders
  let listOfSender = []
  for (const i of sender) {
    if (!(listOfSender.includes(i.ID))) {
      listOfSender.push(i.Email)
    }
  }
  listOfSender.unshift("All Sender")

  const SenderOptions = listOfSender.map((senderData, index) => {
    return {
      value: index,
      label: senderData
    }
  })

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: 'rgba(255, 255, 255, 0.5)',
      }
    },
    control: (base, state) => ({
      ...base,
      background: "#4B4B4B",
      color: "white",
      borderColor: state.isFocused ? "white" : "black",
    }),
    menu: (base) => ({
      ...base,
      background: "red",
    }),
    menuList: (base) => ({
      ...base,
      background: "#4B4B4B",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isSelected
        ? "#FD441D"
        : undefined,
      backgroundColor: isFocused
        ? "#FD441D"
        : undefined,
      zIndex: 1
    }),
  };

  return (
    <div className="filter">
      <Select
        styles={customStyles}
        isClearable
        placeholder="Select Sender..."
        onChange={(option, { action }) => props.getListBySender(option, action)}
        value={props.senderValue}
        options={SenderOptions} />
    </div>
  )
}

export default ListBySender;