import React from "react";
import { useNavigate } from "react-router-dom";

const LogOut = () =>{

    const navigation = useNavigate();
    const logout = () =>{
         navigation("/logout")
         window.location.reload()
    }
    
    return(
        <>
        <button  className="reset_button bg-primary" onClick = {logout} size="sm">Log out</button>
        </>
    )
}

export default LogOut;