import React from "react";
import { faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const SortUp =() =>{
    return(
         <div>
             <FontAwesomeIcon icon={faSortUp}/>
         </div>
    )
}

export default SortUp;