import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Footer from "../Layouts/Footer";
import { useNavigate } from 'react-router-dom';

var axios = require('axios');
var qs = require('qs');
const Login = (props) => {
  // const navigate = useNavigate();
  // const [userName, setUserName] = useState("");
  // const [passWord, setPassWord] = useState("");

  // const submitForm = async (e) => {
  //   e.preventDefault();

  //   var data = qs.stringify({
  //     'grant_type': 'password',
  //     'client_id': 'APIGatewayClientId',
  //     'client_secret': 'APIGatewayAClientSecret',
  //     'scope': 'gateway.fullaccess openid',
  //     'username': userName,
  //     'password': passWord,
  //     'TenantId': '239bf037-ad15-4c98-9144-9bae4d004f2d'
  //   });

  //   var config = {
  //     method: 'post',
  //     url: '/api/LoginAuth',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     },
  //     data: data
  //   };

  //   const listofdata = await axios(config)
  //     .then((response) => {
  //       console.log("reponse", response.data.status);
  //       if (response.data.status === 200) {
  //         console.log("---------------response data ***********************")
  //         navigate('/list')
  //         return response.data;
  //       } else {
  //         return response.data;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  //   console.log("'''''''''''''", listofdata)
  // }
  
  // const myFunction = () => {
  //   var x = document.getElementsByClassName("login_input1");
  //   console.log("x",x)
  //   if (x.type === "password") {
  //     console.log("click on the checkbox")
  //     x.type = "username";
  //   } else {
  //     x.type = "password";
  //     console.log("click on the checkbox-------")
  //   }
  // }

  return (
    <>
     <section className="container-fluid bg">
        <section className="row h-100 justify-content-center align-items-center flex-column">
          <section className="flex-fill justify-content-center align-items-center d-flex">
            <section className="col-md-4 col-sm-12 col-lg-3">
              <Form className="p-4 bg-black rounded-3" onSubmit={props.submitForm}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h4 className="login_header text-white text-center">Welcome to the Message Center</h4>
                </Form.Group>
                <Form.Group className="w-100 d-block text-center">
                  <Button className="btn btn-main submit_btn" type="submit">
                    Login                 
                  </Button>
                </Form.Group>
              </Form>
            </section>
          </section>
          <Footer />
        </section>
      </section> 
      {/* <section className="container-fluid bg">
        <section className="row h-100 justify-content-center align-items-center flex-column">
          <section className="flex-fill justify-content-center align-items-center d-flex">
            <section className="col-md-4 col-sm-12 col-lg-3">

              <Form className="p-4 bg-black rounded-3" onSubmit={submitForm}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h3 className="login_header mb-4 text-white">marbet message - center</h3>
                  <h5 className="signIn mb-3 text-white">Sign In</h5>
                  <Form.Label className="login_label ">Username</Form.Label>
                  <Form.Control type="text" className="login_input" placeholder="Username" onChange={e => setUserName(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="login_label">Password</Form.Label>
                  <Form.Control type="password" className="login_input1" placeholder="Password"
                    onChange={e => setPassWord(e.target.value)} />
                </Form.Group>
                
                <Form.Group className="mb-3">
                  <Form.Check type="checkbox" label="Show Password" onClick={myFunction} />
                </Form.Group>

                <Form.Group>
                  <Button className="btn btn-main w-100 submit_btn" type="submit">
                    Submit
                  </Button>
                </Form.Group>
              </Form>

            </section>
          </section>
          <Footer />
        </section>
      </section> */}
    </>
  )
};

export default Login;
