import React, { useContext, useEffect } from 'react';
import { ParticipantContext } from "../Context/ParticipantState";
import 'bootstrap/dist/css/bootstrap.min.css';
import ListByStatus from '../Components/List/ListByStatus';
import ListByDate from '../Components/List/ListByDate';
import ListBySender from "../Components/List/ListBySender";
import { Container, Row, Col } from "react-bootstrap";
import ListByCampaign from '../Components/List/ListByCampaign';
import useState from 'react-usestateref';
import ResetBtn from '../Components/List/ResetBtn';
import Header from '../Layouts/Header';
import LoadingSpinner from "../Components/List/LoadingSpinner";
import Footer from "../Layouts/Footer";
import RefreshBtn from '../Components/List/RefreshBtn';
import DataTable, { createTheme, SortOrder } from 'react-data-table-component';
import SortUp from '../Components/Sortings/SortUp';

const List = () => {
  const { sender, campaign } = useContext(ParticipantContext);
  const [data, setData] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [, setCountOnlyData, countOnlyRef] = useState();
  const [, setLoading, loadingRef] = useState(false);
  const [emailsPerPage, setEmailsPerPage, emailsPerPageRef] = useState(10);
  const [, setStatusValue, statusValueRef] = useState(" ");
  const [, setStatusSelected, statusSelectedRef] = useState(" ");
  const [, setSenderValue, senderValueRef] = useState(" ");
  const [, setSenderSelected, senderSelectedRef] = useState(" ");
  const [, setCampaignValue, campaignValueRef] = useState(" ");
  const [, setCampaignSelected, campaignSelectedRef] = useState(" ");
  const [, setDateSelected, dateSelectedRef] = useState([(new Date(Date.now() - 86400 * 1000)), new Date()]);
  const [, setPageNumber, pageNumberRef] = useState(1);
  const [, setLoader, loaderRef] = useState(false);
  const [, setSort, sortRef] = useState("asc");

  const columns = [
    {
      name: "Nr.",
      maxWidth: '50px',
      cell: (_row,index) =>  ((pageNumberRef.current-1)*emailsPerPage)+ (index+1),
    },
    {
      name: "Status",
      maxWidth: '150px',
      selector: (row) => <label className={`badge ${row.Status}`}>{row.Status}</label>,
    },
    {
      name: "Contact ID",
      maxWidth: '250px',
      selector: (row) => row.ContactAlt,
    },
    {
      name: "Subject",
      maxWidth: '1000px',
      selector: (row) => row.Subject,
    },
    {
      name: "Date",
      maxWidth: '250px',
      sortable: true,
      selector: (row) => (new Date(row.ArrivedAt)).toLocaleString('en-GB'),
    },
    {
      name: "Campaign Name",
      maxWidth: '350px',
      selector: (row) => campaign.map(obj => { return (row.CampaignID === obj.ID) ? obj.CustomValue : " " }),
    }
  ]

  useEffect(() => {
    window.dispatchEvent(new CustomEvent("navigationhandler"));
    if (isFirstRender === true) {
      countData();
      filteredData();
      setIsFirstRender(false);
    }
    if (loadingRef.current === true) {
      setPageNumber(1);
      setLoading(false);
    }
  })

  const hideLoader = () => {
    setLoader(false);
  }

  const showLoader = () => {
    setLoader(true);
  }

  //function for getting total number of emails
  const countData = async () => {
    let url;
    if (dateSelectedRef.current.length !== 0) {
      url = `/api/TotalEmails?emailPerPage=${emailsPerPageRef.current}&selected=${(pageNumberRef.current-1) * emailsPerPage}&status=${statusSelectedRef.current}&senderID=${senderSelectedRef.current}&campaignID=${campaignSelectedRef.current}&fromDate=${dateSelectedRef.current[0].toISOString()}&lastDate=${dateSelectedRef.current[1].toISOString()}`
    } else {
      url = `/api/TotalEmails?emailPerPage=${emailsPerPageRef.current}&selected=${(pageNumberRef.current-1) * emailsPerPage}&status=${statusSelectedRef.current}&senderID=${senderSelectedRef.current}&campaignID=${campaignSelectedRef.current}&fromDate=${dateSelectedRef.current[0]}&lastDate=${dateSelectedRef.current[1]}`
    }
    await fetch(url)
      .then(res => {
        if (res.status !== 200) {
          throw new Error(`There was an error with status code ${res.status}`)
        }
        return res.json();
      }
      )
      .then(data1 => {
        setCountOnlyData(data1)
      }
    )
  }

  // to sort date in ascending order
  const filteredDataASC = async () => {
    showLoader();
    let url;
    if (dateSelectedRef.current.length !== 0) {
      url = `/api/FilterDataASC?emailPerPage=${emailsPerPageRef.current}&selected=${(pageNumberRef.current-1) * emailsPerPage}&status=${statusSelectedRef.current}&senderID=${senderSelectedRef.current}&campaignID=${campaignSelectedRef.current}&fromDate=${dateSelectedRef.current[0].toISOString()}&lastDate=${dateSelectedRef.current[1].toISOString()}`
    } else {
      url = `/api/FilterDataASC?emailPerPage=${emailsPerPageRef.current}&selected=${(pageNumberRef.current-1) * emailsPerPage}&status=${statusSelectedRef.current}&senderID=${senderSelectedRef.current}&campaignID=${campaignSelectedRef.current}&fromDate=${dateSelectedRef.current[0]}&lastDate=${dateSelectedRef.current[1]}`
    }
    await fetch(url)
      .then(resAsc => {
        if (resAsc.status !== 200) {
          throw new Error(`There was an error with status code ${resAsc.status}`)
        }
        hideLoader();
        return resAsc.json();
      }
      )
      .then(data1 => {
        hideLoader();
        return setData(data1)
      })
  }

  //main filter function for all filters
  const filteredData = async () => {
    if(sortRef.current === 'asc'){
      filteredDataDESC()
    }else if(sortRef.current === 'desc'){
      filteredDataASC()
    }
  }

  // to sort date in descending order
  const filteredDataDESC = async () => {
    showLoader();
    let url;
    if (dateSelectedRef.current.length !== 0) {
      url = `/api/FilterDataDESC?emailPerPage=${emailsPerPageRef.current}&selected=${(pageNumberRef.current-1) * emailsPerPage}&status=${statusSelectedRef.current}&senderID=${senderSelectedRef.current}&campaignID=${campaignSelectedRef.current}&fromDate=${dateSelectedRef.current[0].toISOString()}&lastDate=${dateSelectedRef.current[1].toISOString()}`
    } else {
      url = `/api/FilterDataDESC?emailPerPage=${emailsPerPageRef.current}&selected=${(pageNumberRef.current-1) * emailsPerPage}&status=${statusSelectedRef.current}&senderID=${senderSelectedRef.current}&campaignID=${campaignSelectedRef.current}&fromDate=${dateSelectedRef.current[0]}&lastDate=${dateSelectedRef.current[1]}`
    }
    await fetch(url)
      .then(resDesc => {
        if (resDesc.status !== 200) {
          throw new Error(`There was an error with status code ${resDesc.status}`)
        }
        hideLoader();
        return resDesc.json();
      }
      )
      .then(data1 => {
        hideLoader();
        return setData(data1);
      })
  }

   //change the page in pagination
  const changePage = async (newPerPage) => {
    if(loadingRef.current===true){
      	newPerPage=1;
        setPageNumber(newPerPage);  
    }
    else{
      setPageNumber(newPerPage);
    }
    filteredData();
  }

  //for sorting date 
  const handleSort = (_column,sortDirection) => {
    
    if(sortDirection === "asc"){
      setPageNumber(1);
      filteredDataDESC();
      setSort('asc');
    }
    else if(sortDirection === "desc"){
      setPageNumber(1);
      filteredDataASC();
      setSort('desc');
    }
  }

  //clear filters on close button of react-select
  const clearDataonclose = () => {
    if (statusSelectedRef.current === " " && senderSelectedRef.current === " " && campaignSelectedRef.current === " " && dateSelectedRef.current.length === 0) {
      setLoading(false);
      setCountOnlyData(0);
      setData([]);
    }
    else if (statusSelectedRef.current !== " " || senderSelectedRef.current !== " " || campaignSelectedRef.current !== " " || dateSelectedRef.current.length !== 0) {
      countData();
      filteredData();
    }
  }

  //when click on any row , it will open the new window with contact data 
  const fetchDetailsOnClick = (ContactObj) => {
    window.open(`/contactdetails/${ContactObj.ContactID}`);
  }

 

  // remove all the selected filters => click on reset button
  const resetAllFilters = () => {
    dateSelectedRef.current.length = 0;
    setCountOnlyData(0);
    setData([]);
    statusValueRef.current = " ";
    statusSelectedRef.current = " ";
    senderValueRef.current = " ";
    senderSelectedRef.current = " ";
    campaignValueRef.current = " ";
    campaignSelectedRef.current = " ";
  }

  // function for Status filter
  const getListByStatus = async (event, action) => {
    setLoading(true);
    if (action === "clear") {
      statusSelectedRef.current = " ";
      statusValueRef.current = " ";
      clearDataonclose();
    }
    else {
      setStatusValue(event);
      setStatusSelected(event.value);
      if (event.label === "All Status") {
        setStatusSelected("%20")
        countData();
        filteredData()
      }
      else {
        countData();
        setLoading(true);
        filteredData()
      }
    }
  }

   // function for Date filter
   const getListByDate = async (ranges) => {
    setDateSelected(ranges)
    countData();
    setLoading(true);
    filteredData()
  }

   //function for clean the dates on close button
   const clearDate = () => {
    dateSelectedRef.current.length = 0;
    clearDataonclose();
  }

  //function for Campaign data
  const getListByCampaign = async (event, action) => {
    setLoading(true);
    if (action === "clear") {
      campaignSelectedRef.current = " ";
      campaignValueRef.current = " ";
      clearDataonclose();
    }
    else {
      setCampaignValue(event);
      setCampaignSelected(event.label);

      let getCampaignID;
      setLoading(true);

      let getCampignName = event.label;

      for (const i of campaign) {
        if (i.CustomValue === getCampignName) {
          getCampaignID = i.ID;
        }
      }
      setLoading(true);
      setCampaignSelected(getCampaignID);
      countData();
      filteredData();
    }
  }

  // function for Sender filter
  const getListBySender = async (event, action) => {
    setLoading(true);
    if (action === "clear") {
      senderSelectedRef.current = " ";
      senderValueRef.current = " ";
      clearDataonclose();
    } else {
      setSenderValue(event)
      setSenderSelected(event.label);

      let getSenderID;
      if (event.label === "All Sender") {
        setSenderSelected("%20")
        countData()
        filteredData();
      }
      else {
        let getEmail = event.label;
        for (const i of sender) {
          if (getEmail === i.Email) {
            getSenderID = i.ID;
          }
        }
        setLoading(true);
        setSenderSelected(getSenderID);
        countData()
        filteredData();
      }
    }
  }
 
  //emails per page function in pagination (RowsPerPage)
  const emailPerPageFun = async (newPerPage) => {
    setLoading(true);
    setEmailsPerPage(newPerPage);
    if (statusSelectedRef.current !== " " || senderSelectedRef.current !== " " || dateSelectedRef.current.length !== 0 || campaignSelectedRef.current !== " ") {
      filteredData();
    } 
 }

  //refresh the data on refresh button
  const refreshData = () => {
    if (statusSelectedRef.current !== " " || senderSelectedRef.current !== " " || dateSelectedRef.current.length !== 0 || campaignSelectedRef.current !== " ") {
      countData();
      filteredData();
    }
  }
 
  //Custom theme for react-data-table-component
  createTheme('solarized', {
    background: {
      default: '#4B4B4B',
    },
    border :{
      default :'1px solid red'
    }
  }, 'dark');

  //to override the internal sorting
  const customSort = (rows,_selector,direction) => {
    return rows.sort(() => {
      if(direction === 'desc' ){
        return sortRef.current = 'desc'
        // return setSort('desc');
      }else{
        return sortRef.current = 'asc'
        // return setSort('asc');
      }
    });
   };

  //Display data in a table 
  return (
    <section className='main_container d-flex flex-column'>
      <div className='header_container'>
        <Header totalEmails={countOnlyRef.current} />
      </div>

      <section className='filter_container flex-fill'>
        <Container fluid className="allFilters p-4 bg-mycustom">
          <Row className="mb-2">
            <Col className='col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6'>
              <ListByStatus statusValue={statusValueRef.current} getListByStatus={getListByStatus} />
            </Col>
            <Col className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6'>
              <ListByDate selectedDateValue={dateSelectedRef.current} getListByDate={getListByDate} clearDate={clearDate} />
            </Col>
            <Col className='col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6'>
              <ListBySender senderValue={senderValueRef.current} getListBySender={getListBySender} />
            </Col>
            <Col className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6'>
              <ListByCampaign campaignValue={campaignValueRef.current} getListByCampaign={getListByCampaign} />
            </Col>
            <Col className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-6 col-xs-6'>
              <ResetBtn resetAllFilters={resetAllFilters} />
            </Col>
            <Col className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-6 col-xs-6'>
              <RefreshBtn refreshData={refreshData} />
            </Col>
          </Row>
        </Container>

          <Row className = "mt-4">
            <Col className='col-12'>
              <DataTable
                columns={columns}
                data={data}
                responsive
                pagination
                paginationRowsPerPageOptions= {[10,50,100,500,1000]}
                theme="solarized"
                paginationTotalRows={countOnlyRef.current}
                onChangePage={changePage}
                onChangeRowsPerPage={emailPerPageFun}
                paginationDefaultPage={pageNumberRef.current}
                paginationServer
                progressPending = {loaderRef.current}
                progressComponent={<LoadingSpinner />}
                onRowClicked={fetchDetailsOnClick}
                onSort={handleSort}
                sortIcon={<SortUp/>} 
                sortFunction={customSort}
              />
            </Col>
          </Row>
      </section>

      <Container fluid className="bg-secondary mt-4 h-200">
        <Footer />
      </Container>
    </section>
  );
}

export default List;


