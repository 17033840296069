import React from "react";

const RefreshBtn = (props) =>{

    return(
        <>
        <button onClick = {props.refreshData} className="reset_button bg-primary" size="sm">Refresh</button>
        </>
    )
}

export default RefreshBtn;
