 import React, { createContext, useEffect, useState } from "react";

 export const ParticipantContext = createContext();

 // ? "useContext" hook is used to create common data that can be accessed throughout the component hierarchy 
 // ? without passing the props down manually to each level.

   const ParticipantState = (props) => {
   const [sender,setSender]=useState([]);  
   const [campaign,setCampaign]=useState([]);
  
      const getData = async () => {

        //this comes from SenderData azure function
      await fetch('/api/SenderData')
          .then((resSender)=> {
          if(resSender.status !== 200) {
            throw new Error(`There was an error with status code ${resSender.status}`)
          }
          return resSender.json();
        }
        )
        .then((data) => {
          setSender(data)
      }); 
              
      //this comes from CampaignData azure function
      await fetch('/api/CampaignData')
          .then((resCampaign) => {
          if(resCampaign.status !== 200) {
            throw new Error(`There was an error with status code ${resCampaign.status}`)
          }
          return resCampaign.json();
        }
        )
        .then((data) => {
          setCampaign(data)
      }); 
 };

   useEffect(() => {
      getData();
   }, []);


   return (
     <>  
       <ParticipantContext.Provider value={{ sender, campaign}}>
         {props.children}
      </ParticipantContext.Provider>
     </>
   );
 };

 export default ParticipantState;

