import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../index.css";
import {Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  return (
    <Card.Footer className="text-muted bg-secondary h-15">
      <div className="d-flex flex-wrap align-item-center justify-content-between text-muted bg-secondary">
      <div className="text-white">@ 2022 m.guest salt</div>

        <div>
        <ul className="footer-navigation">
          <li>
            <a href="mailto:info@mguestsalt.com"><FontAwesomeIcon className="me-1" icon= {faEnvelope} />info@mguestsalt.com</a>
          </li>
          
          <li>
            <a href="tel:49079149380251">  <FontAwesomeIcon className="me-1" icon= {faPhone}/>+49 (0) 791 49380 251</a>
          </li>
         
          <li>
          <a href="https://marbet.mguestsalt-test.com/privacy-policy" target="_blank">Privacy Policy</a>          
          </li>
          
          <li>
          <a href="https://marbet.mguestsalt-test.com/legal-notice" target="_blank">Legal Notice</a>          
          </li>
         
          <li>
          <a href="https://marbet.mguestsalt-test.com/terms-service" target="_blank">Terms of Service</a>
          </li>
        </ul>
        </div>
      </div>
    </Card.Footer>
  )
}

export default Footer;