import React, { useContext } from "react";
import { ParticipantContext } from "../../Context/ParticipantState";
import Select from 'react-select';

const ListByCampaign = (props) => {
  //campaign contains all data (json file / array of Objects / records)
  const { campaign } = useContext(ParticipantContext);
  
  //remove duplicate value of campaign
  let listOfCampaign = []
  for (const i of campaign) {
    if (!(listOfCampaign.includes(i.CustomValue))) {
      listOfCampaign.push(i.CustomValue)
    }
  }

  const campiagnOptions = listOfCampaign.map((campaignData) => {
    return {
      value: campaignData,
      label: campaignData
    }
  })

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: 'rgba(255, 255, 255, 0.5)',
      }
    },
    control: (base, state) => ({
      ...base,
      background: "#4B4B4B",
      borderColor: state.isFocused ? "white" : "black",
      // color: state.isSelected ? "white" : "white"
    }),
    menu: (base) => ({
      ...base,
      background: "red",
    }),
    menuList: (base) => ({
      ...base,
      background: "#4B4B4B",
    }),
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      background: isSelected
        ? "#FD441D"
        : undefined,
      backgroundColor: isFocused
        ? "#FD441D"
        : undefined,
      zIndex: 1
    }),
  };

  return (
    <>
      <div className="filter_campaign">
        <Select
          onChange={(option, { action }) => props.getListByCampaign(option, action)}
          placeholder="Select Campaign..."
          styles={customStyles}
          value={props.campaignValue}
          options={campiagnOptions}
          isClearable
        />
      </div>
    </>
  )
}

export default ListByCampaign;