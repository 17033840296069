import React from "react";
import Select from "react-select";

const ListByStatus = (props) => {

  const statusOptions = [
    { value: -1, label: 'All Status' },
    { value: 0, label: 'Processed' },
    { value: 1, label: 'Queued' },
    { value: 2, label: 'Sent' },
    { value: 3, label: 'Opened' },
    { value: 4, label: 'Clicked' },
    { value: 5, label: 'Bounce' },
    { value: 6, label: 'Spam' },
    { value: 7, label: 'Unsub' },
    { value: 8, label: 'Blocked' },
    { value: 9, label: 'SoftBounce' },
    { value: 10, label: 'HardBounce' },
    { value: 11, label: 'Deferred' }
  ]

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: 'rgba(255, 255, 255, 0.5)',
      }
    },
    control: (base, state) => ({
      ...base,
      background: "#4B4B4B",
      borderColor: state.isFocused ? "white" : "black",
    }),
    menu: (base) => ({
      ...base,
      background: "red",
    }),
    menuList: (base) => ({
      ...base,
      background: "#4B4B4B",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isSelected
        ? "#FD441D"
        : undefined,
      backgroundColor: isFocused
        ? "#FD441D"
        : undefined,
    }),
  };

  return (
    <div className="bg-primary">
      <Select
        placeholder="Select Status..."
        styles={customStyles}
        onChange={(option, { action }) => props.getListByStatus(option, action)}
        value={props.statusValue}
        options={statusOptions}
        isClearable
      />
    </div>
  )
}

export default ListByStatus;