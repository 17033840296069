import React from "react";
import { RotatingLines } from "react-loader-spinner";

const LoadingSpinner = () => {

  return (
    <RotatingLines/>
    );  
}

export default LoadingSpinner;