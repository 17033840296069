import React, {  useEffect, useState } from "react";
import { Col,Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

const ContactDetails = () => {
 
  const [contactData, setContactData] = useState([]);
  const { contactInfo } = useParams();

  let updatedArray=[]

  const getContactDetails = async() => {
    await fetch(`/api/ContactData/${contactInfo}`)
    .then(response => {
     if(response.status !== 200) {
       throw new Error(`There was an error with status code ${response.status}`)
     }
     return response.json();
   }
   )
   .then((data) => {
    setContactData(data)
 }); 
}
useEffect(() => { 
  getContactDetails();
},[]);
   

  if(contactData.length !== 0){
    const filteredArray= [
      {
        CreatedAt: (new Date(contactData[0].CreatedAt)).toLocaleString('en-GB'),
        LastActivityAt: (new Date(contactData[0].LastActivityAt)).toLocaleString('en-GB'),
        LastUpdateAt: (new Date(contactData[0].LastUpdateAt)).toLocaleString('en-GB')
      }
    ]
    contactData.map((item,i)=>{
       updatedArray= Object.assign({},item,filteredArray[i])
    })
  }
 
  return(
    <>  
    <div className="contactDetails w-100 text-white bg-primary p-4">
    <div className="container border border-white rounded  p-5">
 
            <div>
              <h2 className="mb-4">Contact Details</h2>
              <Row>
                {updatedArray.length !== 0 && Object.entries(updatedArray).map((k)=>{
                    return(
                        <Col className="col-md-6 col-12">
                           <p className="m-0 fw-bold fs-5">{k[0]}</p> 
                           <p className="mt-0">{k[1].toString()}</p>
                        </Col>
                    )
                  })
              } 
               </Row>   
            </div>
      </div>  
      </div>    
    </>
  )    
};

export default ContactDetails;