import React from 'react';
import List from "./Pages/List";
import ParticipantState from "../src/Context/ParticipantState";
import {
  Route,
  Navigate,
  Routes,
} from "react-router-dom";

import ContactDetails from './Pages/ContactDetails';
import Login from './Pages/Login';


function App() {
  return (
    <>
      <Routes>
        {/* <Route element={<PublicRoutes />}>  */}
          <Route path="/login"  element={<Login />}/>

        {/* <Route element={<ProtectedRoutes auth={auth} loging={loging} />}> */}
          <Route path="/list" element={
            <ParticipantState>
              <List />
            </ParticipantState>
          }
        />

        <Route
          path="/contactdetails/:contactInfo"
          element={<ContactDetails />}
        />

        <Route path="/logout" />

      {/* </Route> */}

      <Route exact path="/" element={<Navigate to="/login" />} />
      </Routes>
    </>
  )
}

export default App;
